// import request from '@/router/axios';

// //查询所有产业链(可用)
// export const allChain = (params) => {
//   return request({
//     url: 'idsp-admin/industry-common/all-chain',
//     method: 'get',
//     params: params
//   })
// }
// //产业链树(可用)-经济图
// export const chainTree = (params) => {
//     return request({
//       url: 'idsp-admin/industry-common/tree',
//       method: 'get',
//       params: params
//     })
//   } 
//   //桑基图,根据产业链ID查询产业节点关系列表
//   export const industryCommonRelateList = (params) => {
//     return request({
//       url: '/idsp-admin/industry-common/relate-list',
//       method: 'get',
//       params: params
//     })
//   }


//   //根据企业ID查询企业画像
//   export const enterpriseDetail = (params) => {
//     return request({
//       url: '/idsp-admin/industry-common/enterprise-detail',
//       method: 'get',
//       params: params
//     })
//   }

//   //查询所有园区
//   export const parkAll = (params) => {
//     return request({
//       url: 'idsp-admin/park/all',
//       method: 'get',
//       params: params
//     })
//   }

//   //政策分页列表
//   export const policyConditionPageList = (data) => {
//     return request({
//       url: 'idsp-admin/policy/condition-page-list',
//       method: 'post',
//       data: data
//     })
//   }
//   //产业政策区域分布
//   export const policyAreaStat = (data) => {
//     return request({
//       url: '/idsp-admin/policy/area-stat',
//       method: 'post',
//       data: data
//     })
//   }
//   //政策级别列表
//   export const policyLevelList = (params) => {
//     return request({
//       url: 'idsp-admin/policy/getLevelList',
//       method: 'get',
//       params: params
//     })
//   }


//   //获取可用的地图服务列表
//   export const mapServiceList = (params) => {
//     return request({
//       url: '/idsp-admin/map_service/mapList',
//       method: 'get',
//       params: params
//     })
//   }

// //获取黄骅信息
//   export const hhInfo = (params) => {
//     return request({
//       url: '/idsp-admin/industry-map/huanghua-info',
//       method: 'get',
//       params: params
//     })
//   }





//     //获取首页政策热点图
//     export const getIndustryListWithCount = (params) => {
//       return request({
//         url: '/idsp-admin/policy/getIndustryListWithCount',
//         method: 'get',
//         params: params
//       })
//     }
//     //产业领域树
//     export const industryTree = (params) => {
//       return request({
//         url: '/idsp-admin/industry-common/industry-select-tree',
//         method: 'get',
//         params: params
//       })
//     }
//     //国际行标树
//     export const gbIndustryTree = (params) => {
//       return request({
//         url: '/idsp-admin/industry-common/gb-industry-select-tree',
//         method: 'get',
//         params: params
//       })
//     }


//     //政策列表里面饼图
//     export const industryStat = (data) => {
//       return request({
//         url: '/idsp-admin/policy/industry-stat',
//         method: 'post',
//         data: data
//       })
//     }
//         //政策详情
//         export const policyDetail = (params) => {
//           return request({
//             url: '/idsp-admin/policy/detail',
//             method: 'get',
//             params,
//           })
//         }
   
import {$get, $post} from '../../http';
import {baseUrl} from "@/request/global";

var api = baseUrl

// export const enterpriselist = (params) => {
//   return $get(api+'/idsp-pc/login/enterprise-list',params)
// }
// export const reqrelateenterprise = (params) => {
//   return $post(api+'/idsp-pc/login/req-relate-enterprise',params)
// }



export const enterprisePageList = (params) => {
  return $post(api+'/idsp-admin/industry-common/enterprise-page-list',params)
}
//   //根据多条件查询企业分页列表
//   export const enterprisePageList = (data) => {
//     return request({
//       url: '/idsp-admin/industry-common/enterprise-page-list',
//       method: 'post',
//       data: data
//     })
//   }
export const enterpriseSearchName = (params) => {
  return $get(api+'/idsp-admin/industry-common/enterprise-search-name',params)
}

//   //快速检索
//   export const enterpriseSearchName = (params) => {
//     return request({
//       url: '/idsp-admin/industry-common/enterprise-search-name',
//       method: 'get',
//       params: params
//     })
//   }

export const downloadReport = (params) => {
  return $get(api+'/idsp-admin/industry-enterprise-info/download-report',params)
}
//   //下载或预览
//   export const downloadReport = (params) => {
//     return request({
//       responseType: "blob", //告诉服务器想到的响应格式
//       url: '/idsp-admin/industry-enterprise-info/download-report',
//       method: 'get',
//       params: params
//     })
//   }


  
  




