<template>
   <div class="progressCon">
        <el-progress type="circle" :stroke-width="widthNum"  :format="format" :percentage="count"></el-progress>
        <!-- 定义修改svg --> 
        <div class="svgCon"> 
            <svg width="100%" height="100%"> 
                <defs> 
                    <linearGradient id="Progress_Two" x1="0%" y1="0%" x2="100%" y2="0%"> 
                        <stop offset="0%" :style="'stop-color:'+colorStart" stop-opacity="0.8"></stop> 
                        <stop offset="100%" :style="'stop-color:'+colorEnd" stop-opacity="1"></stop>
                    </linearGradient> 
                </defs> 
            </svg> 
        </div>
    </div>    
</template>
<script>
export default {
  name: "MyProgress",
  props: {
      widthNum: {
      type: Number,
      default: 6,
    },
    colorStart:{
      type: String,
      default: '#6BDFD4'
    },
    colorEnd:{
      type: String,
      default: '#6596EA'
    },
    count: {
      type: Number,
      default: 100,
    },
  },
  data(){
    return { 
        
    }
  },
    methods: {
        //格式化进度
        format(val){
           return val
        },       
    },
}
</script>
<style lang="scss" scoped>
.progressCon {
    /deep/ {
	  .el-progress {
	    margin: 0 6px;
	    position: relative;
	  }
      svg>path:nth-child(2) {
	  	// #write 此处的id就是定义的svg标签id 做替换即可 
	    stroke: url(#Progress_Two); 
	  }
	  // 进度条的图形样式，控制翻转
	  .el-progress-circle {
	    transform: rotateY(180deg);
	  }
	}
    .svgCon {
        width:0px; 
        height: 0px;
    }
    .cctitle{
      color:#2E2E2E;
      font-size:15px;
      font-weight:bold;
      margin-top:16px;
    }


 
    /deep/ .el-table .cell {
      line-height: 20px;
    }
}
</style>