// import request from '@/router/axios';














 

  











//     //人员信息查询=======================================================
//     export const personInfo = (params) => {
//         return request({
//           url: '/api/',
//           method: 'get',
//           params: params
//         })
//       }

//       //查询企业实际控制人或企业列表及股份占比-企业关系=================================
//       export const getPersonEntPercentForRe = (params) => {
//         return request({
//           url: '/api/',
//           method: 'get',
//           params: params
//         })
//       }

//       //企业信息查询===============================================
//       // export const getEntInfo = (params) => {
//       //   return request({
//       //     url: '/api/',
//       //     method: 'get',
//       //     params: params
//       //   })
//       // }
//       //查询企业实际控制人或企业列表及股份占比-股权穿透=========================
//       export const getPersonEntPercentForGu = (params) => {
//         return request({
//           url: '/api/',
//           method: 'get',
//           params: params
//         })
//       }

      
//       //分页查询新企业申请记录
//       export const enterpriseReqList = (params) => {
//         return request({
//           url: '/idsp-admin/enterprise-manage/enterprise-req-list',
//           method: 'get',
//           params: params
//         })
//       }
//       //新企业申请审核
//       export const approveNewEnterpriseRequest = (params) => {
//         return request({
//           url: 'idsp-admin/enterprise-manage/approve-new-enterprise-request',
//           method: 'get',
//           params: params
//         })
//       }




      


      

 

import {$get, $post} from '../../http';
import {baseUrl} from "@/request/global";

var api = baseUrl

// export const enterpriselist = (params) => {
//   return $get(api+'/idsp-pc/login/enterprise-list',params)
// }
// export const reqrelateenterprise = (params) => {
//   return $post(api+'/idsp-pc/login/req-relate-enterprise',params)
// }



export const deleteReceiveEnterprise = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/delete-receive-enterprise',params)
}
//   //移除已领取的企业
//   export const deleteReceiveEnterprise = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/delete-receive-enterprise',
//       method: 'post',
//       data: data
//     })
//   }
export const deleteReceiveName = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/delete-receive-name',params)
}
//   //删除已领取的企业名单
//   export const deleteReceiveName = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/delete-receive-name',
//       method: 'post',
//       data: data
//     })
//   }
export const myCollectNameList = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/my-collect-name-list',params)
}
//       //企业领取-查询我的名单列表
//       export const myCollectNameList = (data) => {
//         return request({
//           url: 'idsp-admin/industry-enterprise-info/my-collect-name-list',
//           method: 'post',
//           data
//         })
//       }
export const myEnterpriseReceiveList = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/my-enterprise-receive-list',params)
}
//    //查询已领取的企业列表
//    export const myEnterpriseReceiveList = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/my-enterprise-receive-list',
//       method: 'post',
//       data
//     })
//   }
export const updateReceiveName = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/update-receive-name',params)
}
//   //编辑已领取的企业名单
//   export const updateReceiveName = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/update-receive-name',
//       method: 'post',
//       data: data
//     })
//   }


export const holdRelateList = (params) => {
  return $get(api+'/idsp-admin/industry-enterprise-info/hold-relate-list',params)
}
//   //关联图谱-股权穿透图
//   export const holdRelateList = (params) => {
//     return request({
//       url: '/idsp-admin/industry-enterprise-info/hold-relate-list',
//       method: 'get',
//       params: params
//     })
//   }

export const holderRelateList = (params) => {
  return $get(api+'/idsp-admin/industry-enterprise-info/holder-relate-list',params)
}
//     //关联图谱-实际控股人
//     export const holderRelateList = (params) => {
//       return request({
//         url: '/idsp-admin/industry-enterprise-info/holder-relate-list',
//         method: 'get',
//         params: params
//       })
//     }

export const conditionRelateList = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/condition-relate-list',params)
}
//       //关联图谱-企业关系网
//   export const conditionRelateList = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/condition-relate-list',
//       method: 'post',
//       data: data
//     })
//   }


export const holderEnterpriseInfo = (params) => {
  return $get(api+'/idsp-admin/industry-enterprise-info/holder-enterprise-info',params)
}
//   //关联图谱-根据企业ID查询企业信息
//   export const holderEnterpriseInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/holder-enterprise-info',
//       method: 'get',
//       params: params
//     })
//   }


export const holderPersonInfo = (params) => {
  return $get(api+'/idsp-admin/industry-enterprise-info/holder-person-info',params)
}
//   //关联图谱-根据人员ID查询人员信息
//   export const holderPersonInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/holder-person-info',
//       method: 'get',
//       params: params
//     })
//   }


export const holderRelateSelectList = (params) => {
  return $get(api+'/idsp-admin/industry-enterprise-info/holder-relate-select-list',params)
}
//       //根据公司id查询控股人
//       export const holderRelateSelectList = (params) => {
//         return request({
//           url: '/idsp-admin/industry-enterprise-info/holder-relate-select-list',
//           method: 'get',
//           params: params
//         })
//       }

export const batchReceiveEnterprise = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/batch-receive-enterprise',params)
}

//   //领取企业
//   export const batchReceiveEnterprise = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/batch-receive-enterprise',
//       method: 'post',
//       data: data
//     })
//   }

export const createCollectName = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/create-collect-name',params)
}
//       //创建名单
//       export const createCollectName = (data) => {
//         return request({
//           url: 'idsp-admin/industry-enterprise-info/create-collect-name',
//           method: 'post',
//           data
//         })
//       }


export const updateEnterpriseStatus = (params) => {
  return $post(api+'/idsp-admin/industry-enterprise-info/update-receive-status',params)
}
//     //更改状态，领取企业
// export const updateEnterpriseStatus = (data) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/update-receive-status',
//       method: 'post',
//       data: data
//     })
//   }