// import request from '@/router/axios';

// //根据键盘的字查询匹配的企业列表
// export const enterpriceListByName = (params) => {
//     return request({
//       url: '/api/',
//       method: 'get',
//       params: params
//     })
//   }











import {$get, $post} from '../../http';
import {baseUrl} from "@/request/global";

var api = baseUrl


// export const downloadReport = (params) => {
//     return $get(api+'/idsp-admin/industry-enterprise-info/download-report',params)
//   }
export const basicInfo = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/basic-info',params)
  }
  //   //根据企业ID查询企业基本信息
//   export const basicInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/basic-info',
//       method: 'get',
//       params: params
//     })
//   }

export const businessDanger = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/business-danger',params)
  }
//   //根据企业ID查询企业经营风险
//   export const businessDanger = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/business-danger',
//       method: 'get',
//       params: params
//     })
//   }

export const detailInfo = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/detail',params)
  }
//   //根据企业ID查询企业画像详情
//   export const detailInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/detail',
//       method: 'get',
//       params: params
//     })
//   }


export const developmentInfo = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/development',params)
  }
//   //根据企业ID查询企业发展
//   export const developmentInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/development',
//       method: 'get',
//       params: params
//     })
//   }

export const knowledgeProperty = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/knowledge-property',params)
  }
//   //根据企业ID查询企业知识产权
//   export const knowledgeProperty = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/knowledge-property',
//       method: 'get',
//       params: params
//     })
//   }

export const legalDanger = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/legal-danger',params)
  }
//   //根据企业ID查询企业司法风险
//   export const legalDanger = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/legal-danger',
//       method: 'get',
//       params: params
//     })
//   }

export const listingInformation = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/list-info',params)
  }
//     //根据企业ID查询企业上市信息
//     export const listingInformation = (params) => {
//       return request({
//         url: 'idsp-admin/industry-enterprise-info/list-info',
//         method: 'get',
//         params: params
//       })
//     }

export const performanceInfo = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/performance',params)
  }
//   //根据企业ID查询企业经营状况
//   export const performanceInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/performance',
//       method: 'get',
//       params: params
//     })
//   }

export const relationInfo = (params) => {
    return $get(api+'/idsp-admin/industry-enterprise-info/relation-info',params)
  }
//   //根据企业ID查询企业关系信息
//   export const relationInfo = (params) => {
//     return request({
//       url: 'idsp-admin/industry-enterprise-info/relation-info',
//       method: 'get',
//       params: params
//     })
//   }





























