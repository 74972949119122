<template>



<div class="zchjs">
    <div class="header">
      <div class="h1">商机洞察</div>
      <div class="h2">数据线索挖掘、多维度精准客户、助企精准获客</div>
    </div>
   <div> 
    <div class="baogao">
      <div class="company">
  <div class="commonmlr"> 
    <div style="margin-left:30px;"> 
      <FangziHead :ttPass="'企业详情'"></FangziHead>
    </div>

    <div style="position:absolute;top:30px;right:60px;z-index: 100;">
        <div class="huaxiangzones"> 
          <el-radio-group v-model="activeTab"   @input="tabChange" size="mini">
      <el-radio-button label="0">企业画像</el-radio-button>
      <el-radio-button label="1">关联图谱</el-radio-button>
      <el-radio-button label="2">评价报告</el-radio-button>
      <el-radio-button label="3">精准获客</el-radio-button>
      <el-radio-button label="4">企业管理</el-radio-button>
    </el-radio-group>
        </div>
   
      </div>
  <!-- <div class="searchBar">
     <div class="searchInput">
        <el-input
          placeholder="请输入企业名称关键字"
          v-model.trim="input"
          class="input-with-select"
          size="small"
          clearable=""
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div class="tabChange">
      </div>
  </div> -->
  <div>
    <div class="name">{{allInfo.name}}</div>
    <div class="flex">
      <div class="flex-1">
        <div class="h1">
          <span class="nodeblue"></span>  <span class="title3">企业评分</span>
        </div>
        <div class="h2 flex">
          <div class="flex-1">
          </div>
          <div class="flex-1 fs_head" style="margin-right:130px;position: relative;">
            <ProgressOne :widthNum="12" :colorStart="'#6BDFD4'" :colorEnd="'#6596EA'" :count='parseInt(allInfo.strength)'></ProgressOne>
            <div v-if="allInfo.strength=='' ||allInfo.strength==-1" style="position:absolute;left:calc(50% - 36px);margin-top:-80px;font-size:18px;font-weight: bold;background-color: white;">暂无评分</div>
            <div class="cctitle">综合实力</div>
          </div>
          <div class="flex-1" style="position: relative;">
            <ProgressTwo :widthNum="12" :colorStart="'#88A2F7'" :colorEnd="'#9775F4'" :count='parseInt(allInfo.innovation)'></ProgressTwo>
            <div v-if="allInfo.innovation=='' ||allInfo.innovation==-1" style="position:absolute;left:calc(50% - 36px);margin-top:-80px;font-size:18px;font-weight: bold;background-color: white;">暂无评分</div>
            <div class="cctitle">创新能力</div>
          </div>
          <div class="flex-1" style="margin-left:130px;position: relative;">
            <ProgressThree :widthNum="12" :colorStart="'#4BCBE1'" :colorEnd="'#4EE2C2'" :count='parseInt(allInfo.legal)'></ProgressThree>
            <div v-if="allInfo.legal=='' ||allInfo.legal==-1" style="position:absolute;left:calc(50% - 36px);margin-top:-80px;font-size:18px;font-weight: bold;background-color: white;">暂无评分</div>
            <div class="cctitle">合法合规</div>
          </div>
          <div class="flex-1">
          </div>
        </div>
      </div>
      <div class="right">
        <div class="flex bottoms" @click="gotoHuaxiang(1,'first')">
          <div class="circle" >
            <el-image
      style="width: 30px; height: 30px;margin:10px 0 0 10px;"
      :src="require('@/assets/img/map/qiyetupu.png')"
      ></el-image>
          </div>
          <div >
            <div class="tuputext">企业图谱</div>
            <div class="bottom1">瞬息掌握企业关系</div>
          </div>
        </div>
        <div class="flex bottoms" @click="gotoHuaxiang(1,'third')">
          <div class="circle">
            <el-image
      style="width: 30px; height: 30px;margin:10px 0 0 10px;"
      :src="require('@/assets/img/map/guquanchuantoutu.png')"
      ></el-image>
          </div>
          <div >
            <div class="tuputext">股权穿透图</div>
            <div class="bottom1">挖掘深层股权结构</div>
          </div>
        </div>
        <div class="flex bottoms" @click="gotoHuaxiang(1,'second')">
          <div class="circle">   <el-image
      style="width: 30px; height: 30px;margin:10px 0 0 10px;"
      :src="require('@/assets/img/map/shijikongzhiren.png')"
      ></el-image></div>
          <div >
            <div class="tuputext">实际控制人</div>
            <div class="bottom1">辨识最终控制路径</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex line">
      <div class="flex-1">成立时间 ： {{allInfo.setupDate}}</div>
      <div class="flex-1">注册资本 ： {{allInfo.regAmount==-1?'暂无数据':allInfo.regAmount+"（万元）"}}</div>

      <div class="flex-1">注册地址：{{allInfo.address}}</div>
    </div>
    <div>
      <el-button type="primary" class="btn" @click="btnLingqu" style="margin-left:0;">关注</el-button>
      <el-popover
  placement="top"
  width="160"
  v-model="visibleDownload">
  <p>选择报告类型</p>
  <div style="text-align: right; margin: 0">
    <el-button type="primary" size="mini" @click="btnDownReport(1)" style="margin-bottom: 2px;">全景报告</el-button><br />
    <el-button type="primary" size="mini" @click="btnDownReport(2)" style="margin-bottom: 2px;">企业综合能力报告</el-button><br />
    <el-button size="mini" type="text" @click="visibleDownload = false" >取消</el-button><br />
  </div>
  <el-button slot="reference" class="btn" type="primary">下载报告</el-button>
</el-popover>
      <el-button type="primary" class="btn" @click="btnEditStatus">更改状态</el-button>
      <div class="genjinxinxi"> 
        <!-- <el-descriptions  :column="2" border style="margin: 15px 0">
          <el-descriptions-item label="跟进状态" label-class-name="my-label width146" content-class-name="my-content">{{allInfo.collectRemark}}</el-descriptions-item>
          <el-descriptions-item label="所属名单"  label-class-name="my-label width146">{{allInfo.collectName}}</el-descriptions-item>
        </el-descriptions> -->
        <p class="pnomp"  >跟进状态：{{allInfo.collectRemark}}</p>
        <p class="pnomp" style="margin-top:20px;">所属名单：{{allInfo.collectName}}</p>
      </div>

    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true" style="background: #fff;border:1px dashed #ccc">
      <el-tab-pane label="基本信息" name="first" style="padding: 0 36px 12px 36px">
        <div class="title">
          <span class="nodeblue"></span>  <span class="title3">基本信息</span>
        </div>
        <el-descriptions  :column="2" border style="margin: 15px 0;font-size: 12px;">
          <el-descriptions-item label="企业名称" label-class-name="my-label width146" content-class-name="my-content">{{allInfo.name}}</el-descriptions-item>
          <el-descriptions-item label="法定代表人"  label-class-name="my-label width146">{{allInfo.legalPerson}}</el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码"  label-class-name="my-label width146">{{allInfo.usci}}</el-descriptions-item>
          <el-descriptions-item label="电话"  label-class-name="my-label width146">{{allInfo.phone}}</el-descriptions-item>
          <el-descriptions-item label="组织机构代码"  label-class-name="my-label width146">{{allInfo.orgCode}}</el-descriptions-item>
          <el-descriptions-item label="注册资本"  label-class-name="my-label width146">{{allInfo.regAmount==-1?'暂无数据':allInfo.regAmount+"（万元）"}}</el-descriptions-item>
          <el-descriptions-item label="登记状态"  label-class-name="my-label width146">{{allInfo.regStatus}}</el-descriptions-item>
          <el-descriptions-item label="成立日期"  label-class-name="my-label width146">{{allInfo.setupDate}}</el-descriptions-item>
          <el-descriptions-item label="核准日期"  label-class-name="my-label width146">{{allInfo.approvalDate}}</el-descriptions-item>
          <el-descriptions-item label="所属行业"  label-class-name="my-label width146">{{allInfo.industry}}</el-descriptions-item>
          <el-descriptions-item label="企业类型"  label-class-name="my-label width146">{{allInfo.type}}</el-descriptions-item>
          <el-descriptions-item label="注册地址"  label-class-name="my-label width146">{{allInfo.address}}</el-descriptions-item>
          <el-descriptions-item label="登记机关"  label-class-name="my-label width146">{{allInfo.regAuthority}}</el-descriptions-item>
          <el-descriptions-item label="历史名称"  label-class-name="my-label width146">{{allInfo.historyName}}</el-descriptions-item>
          <el-descriptions-item label="英文名称"  label-class-name="my-label width146">{{allInfo.engName}}</el-descriptions-item>
          <el-descriptions-item label="开户银行"  label-class-name="my-label width146">{{allInfo.bank}}</el-descriptions-item>
         
          <el-descriptions-item label="经营范围"  span="1" label-class-name="my-label width146">{{allInfo.businessScope}}</el-descriptions-item>
          <el-descriptions-item label="企业简介"  span="1" label-class-name="my-label width146">{{allInfo.introduction}}</el-descriptions-item>
        </el-descriptions>
        <div class="title">
          <span class="nodeblue"></span>  <span class="title3">股东及出资信息</span>
        </div>
        <el-table :data="baseInfo.enterpriseHolderList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="shareholderName" label="股东名称" ></el-table-column>
          <el-table-column prop="shareholderType" label="股东类型" ></el-table-column>
          <el-table-column prop="investAmount" label="认缴出资额（万元）" ></el-table-column>
          <el-table-column prop="investRate" label="出资比例（%）" ></el-table-column>
          <el-table-column prop="investDate" label="出资日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">历史退出股东</span></div>
        <el-table :data="baseInfo.enterpriseHistoryHolderList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="shareholderName" label="股东名称" ></el-table-column>
          <el-table-column prop="entryDate" label="进入时间" ></el-table-column>
          <el-table-column prop="leaveDate" label="退出时间" ></el-table-column>
          <el-table-column prop="holdDuration" label="持股时长" ></el-table-column>
        </el-table>
        <div class="title"> <span class="nodeblue"></span>  <span class="title3">主要管理人员</span></div>
        <el-descriptions  :column="1" border style="margin: 15px 0;font-size:12px;" class="manage">
          <el-descriptions-item label="董事会成员" label-class-name="my-label width146" content-class-name="my-content">{{baseInfo.enterpriseManager.boardMember}}</el-descriptions-item>
          <el-descriptions-item label="监事会成员"  label-class-name="my-label width146">{{baseInfo.enterpriseManager.superBoardMember}}</el-descriptions-item>
          <el-descriptions-item label="高管人员"  label-class-name="my-label width146">{{baseInfo.enterpriseManager.managerMember}}</el-descriptions-item>
          <el-descriptions-item label="其他人员"  label-class-name="my-label width146">{{baseInfo.enterpriseManager.otherMember}}</el-descriptions-item>
        </el-descriptions>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">历史退出高管</span></div>
        <el-table :data="baseInfo.enterpriseLeaveManagerList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="managerName" label="高管名称" ></el-table-column>
          <el-table-column prop="managerJob" label="高管职务" ></el-table-column>
          <el-table-column prop="entryDate" label="进入时间" ></el-table-column>
          <el-table-column prop="leaveDate" label="退出时间" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">变更信息</span></div>
        <el-table :data="baseInfo.enterpriseChangeList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="changeItem" label="变更事项" ></el-table-column>
          <el-table-column prop="before" label="变更前内容" ></el-table-column>
          <el-table-column prop="after" label="变更后内容" ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="司法风险" name="second"  style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">法院公告</span></div>
        <el-table :data="lawInfo.enterpriseCourtAnnounceList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="party" label="当事人"></el-table-column>
          <el-table-column prop="announceType" label="公告类型" ></el-table-column>
          <el-table-column prop="announceCourt" label="公告法院" ></el-table-column>
          <el-table-column prop="area" label="地区" ></el-table-column>
          <el-table-column prop="announceDate" label="公告日期" ></el-table-column>
          <el-table-column prop="announceContent" label="公告内容" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">法院执行</span></div>
        <el-table :data="lawInfo.enterpriseCourtExecList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="caseNo" label="案号" ></el-table-column>
          <el-table-column prop="execCourt" label="执行法院" ></el-table-column>
          <el-table-column prop="execAmount" label="执行标的（元）" ></el-table-column>
          <el-table-column prop="caseDate" label="立案时间" ></el-table-column>
          <el-table-column prop="caseStatus" label="状态" ></el-table-column>
          <el-table-column prop="cancelDate" label="撤出公示时间" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">裁判文书</span></div>
        <el-table :data="lawInfo.enterpriseTrialDocList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="caseType" label="案件类型" ></el-table-column>
          <el-table-column prop="caseNo" label="案号" ></el-table-column>
          <el-table-column prop="caseName" label="案件名称" ></el-table-column>
          <el-table-column prop="partyType" label="当事人类型" ></el-table-column>
          <el-table-column prop="trialDate" label="审判日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">终本案件</span></div>
        <el-table :data="tableData" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业失信</span></div>
        <el-table :data="tableData" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">高管失信</span></div>
        <el-table :data="lawInfo.enterpriseManagerDebtList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="managerName" label="高管名称" ></el-table-column>
          <el-table-column prop="publishDate" label="发布时间" ></el-table-column>
          <el-table-column prop="obligation" label="生效法律文书确定的义务" ></el-table-column>
          <el-table-column prop="execStatus" label="履行情况" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">司法协助</span></div>
        <el-table :data="lawInfo.enterpriseCourtHelpList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="debtor" label="被执行人" ></el-table-column>
          <el-table-column prop="shareholdAmount" label="股权数额" ></el-table-column>
          <el-table-column prop="execCourt" label="执行法院" ></el-table-column>
          <el-table-column prop="docNo" label="执行通知文书号" ></el-table-column>
          <el-table-column prop="type" label="类型" ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="知识产权" name="third"  style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">专利申请</span></div>
        <el-table :data="knowledgeInfo.enterprisePatentList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="patentType" label="专利类型" ></el-table-column>
          <el-table-column prop="patentName" label="专利名称" ></el-table-column>
          <el-table-column prop="petentStatus" label="当前状态" ></el-table-column>
          <el-table-column prop="reqDate" label="申请日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">商标注册</span></div>
        <el-table :data="knowledgeInfo.enterpriseBrandList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="regNo" label="注册号" ></el-table-column>
          <el-table-column prop="brandName" label="商标名称" ></el-table-column>
          <el-table-column prop="regArea" label="注册领域" ></el-table-column>
          <el-table-column prop="reqDate" label="申请日期" ></el-table-column>
          <el-table-column prop="brandStatus" label="商标状态" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">软件著作权</span></div>
        <el-table :data="knowledgeInfo.enterpriseSoftwareList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <!-- <el-table-column prop="date" label="序号" ></el-table-column> -->
          <el-table-column prop="fullName" label="软件全称" ></el-table-column>
          <el-table-column prop="shortName" label="软件简称" ></el-table-column>
          <el-table-column prop="version" label="版本号" ></el-table-column>
          <el-table-column prop="regNo" label="登记号" ></el-table-column>
          <el-table-column prop="devFinishDate" label="开发完成日期" ></el-table-column>
          <el-table-column prop="firstPublishDate" label="首次发布日期" ></el-table-column>
          <el-table-column prop="regDate" label="登记日期" ></el-table-column>
          <el-table-column prop="rightMethod" label="权利取得方式" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">作品著作权</span></div>
        <el-table :data="knowledgeInfo.enterpriseWorksList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="worksName" label="作品名称" ></el-table-column>
          <el-table-column prop="worksType" label="作品类别" ></el-table-column>
          <el-table-column prop="finishDate" label="创作完成时间" ></el-table-column>
          <el-table-column prop="regDate" label="登记日期" ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="经营状况" name="fourth"   style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">招聘信息</span></div>
        <el-table :data="opesituationInfo.enterpriseRecruitList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="jobName" label="职位名称" ></el-table-column>
          <el-table-column prop="recruitQty" label="招聘人数" ></el-table-column>
          <el-table-column prop="workYears" label="工作经验" ></el-table-column>
          <el-table-column prop="minEdu" label="最低学历" ></el-table-column>
          <el-table-column prop="workLocation" label="工作地点" ></el-table-column>
          <el-table-column prop="recruitDate" label="招聘日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业资质</span></div>
        <el-table :data="opesituationInfo.enterpriseQualifyList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="qualifyName" label="资质名称" ></el-table-column>
          <el-table-column prop="certNo" label="证书编号" ></el-table-column>
          <el-table-column prop="certDate" label="发证时间" ></el-table-column>
          <el-table-column prop="validDate" label="有效期至" ></el-table-column>
          <el-table-column prop="certAuthority" label="认证机构" ></el-table-column>
          <el-table-column prop="certContent" label="认证内容" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业荣誉</span></div>
        <el-table :data="opesituationInfo.enterpriseHonorList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="honorName" label="荣誉名称" ></el-table-column>
          <el-table-column prop="rateAgency" label="评价机构" ></el-table-column>
          <el-table-column prop="rateDate" label="评选时间" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">招投标</span></div>
        <el-table :data="opesituationInfo.enterpriseTenderList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="projectName" label="项目名称" ></el-table-column>
          <el-table-column prop="purchaser" label="采购人" ></el-table-column>
          <el-table-column prop="publishDate" label="发布时间" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">进出口信息</span></div>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">贸易商品信息</span></div>
        <el-table :data="opesituationInfo.enterpriseTradeList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="type" label="类别" ></el-table-column>
          <el-table-column prop="tradeDate" label="时间" ></el-table-column>
          <el-table-column prop="country" label="贸易国" ></el-table-column>
          <el-table-column prop="goods" label="商品" ></el-table-column>
          <el-table-column prop="amount" label="金额（美元）" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">注册信息</span></div>
        <el-descriptions  :column="2" border style="margin: 15px 0; font-size:12px;">
          <el-descriptions-item label="统一社会信用代码" label-class-name="my-label width146" content-class-name="my-content">{{opesituationInfo.enterpriseTradeReg.usci}}</el-descriptions-item>
          <el-descriptions-item label="海关注册编码"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.regCode}}</el-descriptions-item>
          <el-descriptions-item label="注册日期"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.regDate}}</el-descriptions-item>
          <el-descriptions-item label="组织机构代码"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.orgCode}}</el-descriptions-item>
          <el-descriptions-item label="企业中文名称"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.chineseName}}</el-descriptions-item>
          <el-descriptions-item label="注册海关"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.regCustoms}}</el-descriptions-item>
          <el-descriptions-item label="工商注册地址"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.regAddress}}</el-descriptions-item>
          <el-descriptions-item label="行政区划"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.adminArea}}</el-descriptions-item>
          <el-descriptions-item label="经济区划"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.economyArea}}</el-descriptions-item>
          <el-descriptions-item label="经营类别"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.businessType}}</el-descriptions-item>
          <el-descriptions-item label="特殊贸易区域"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.specialArea}}</el-descriptions-item>
          <el-descriptions-item label="行业种类"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.industry}}</el-descriptions-item>
          <el-descriptions-item label="报关有效期"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.validDate}}</el-descriptions-item>
          <el-descriptions-item label="海关注销标志"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.cancelFlag}}</el-descriptions-item>
          <el-descriptions-item label="年报情况"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.annualReport}}</el-descriptions-item>
          <el-descriptions-item label="信用信息异常情况"  label-class-name="my-label width146">{{opesituationInfo.enterpriseTradeReg.creditException}}</el-descriptions-item>
        </el-descriptions>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">信用等级</span></div>
        <el-table :data="opesituationInfo.enterpriseCreditList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="certDate" label="认定时间" ></el-table-column>
          <el-table-column prop="certNo" label="认证证书编号" ></el-table-column>
          <el-table-column prop="creditLevel" label="信用等级" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">网站域名</span></div>
        <el-table :data="opesituationInfo.enterpriseDomainList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="name" label="网站名称" ></el-table-column>
          <el-table-column prop="domain" label="网站域名" ></el-table-column>
          <el-table-column prop="licenceNo" label="网站许可证" ></el-table-column>
          <el-table-column prop="head" label="网站负责人" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">网站商铺</span></div>
        <el-table :data="opesituationInfo.enterpriseWebshopList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="name" label="商铺名称" ></el-table-column>
          <el-table-column prop="url" label="商铺链接" ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="企业发展" name="five"   style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">融资历程</span></div>
        <div style="margin: 15px 0">
          <el-table :data="developInfo.enterpriseCourseList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="publishDate" label="披露日期" ></el-table-column>
          <el-table-column prop="amount" label="交易金额" ></el-table-column>
          <el-table-column prop="financeRound" label="融资轮次" ></el-table-column>
          <el-table-column prop="valuation" label="估值" ></el-table-column>
          <el-table-column prop="rate" label="比例" ></el-table-column>
          <el-table-column prop="investor" label="投资方" ></el-table-column>
        </el-table>
        </div>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业年报</span>
          <el-radio-group v-model="valueYearDev" size="mini" style="float:right;" @input="btnYearDev">
      <el-radio-button v-for="item in yearList" :key="item" :label="item">{{item}}</el-radio-button>
   
    </el-radio-group>
        </div>

        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业基本信息</span></div>
        <el-descriptions  :column="2" border style="margin: 15px 0; font-size:12px;">
          <el-descriptions-item label="统一社会信用代码" label-class-name="my-label width146" content-class-name="my-content">{{developInfo.enterpriseYearInfo.usci}}</el-descriptions-item>
          <el-descriptions-item label="企业名称"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.name}}</el-descriptions-item>
          <el-descriptions-item label="企业联系电话"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.phone}}</el-descriptions-item>
          <el-descriptions-item label="邮政编码"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.postalCode}}</el-descriptions-item>
          <el-descriptions-item label="电子邮箱"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.email}}</el-descriptions-item>
          <el-descriptions-item label="是否转让股东股权"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.transFlag}}</el-descriptions-item>
          <el-descriptions-item label="登记状态"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.regStatus}}</el-descriptions-item>
          <el-descriptions-item label="是否有网站或网店"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.websiteFlag}}</el-descriptions-item>
          <el-descriptions-item label="是否投资或购买其他公司股权"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.investFlag}}</el-descriptions-item>
          <el-descriptions-item label="从业人数"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.employeeQty}}</el-descriptions-item>
          <el-descriptions-item label="企业通讯地址"  label-class-name="my-label width146">{{developInfo.enterpriseYearInfo.address}}</el-descriptions-item>
        </el-descriptions>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">网站或网店信息</span></div>
        <el-table :data="developInfo.enterpriseYearWebList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="type" label="类型" ></el-table-column>
          <el-table-column prop="name" label="名称" ></el-table-column>
          <el-table-column prop="url" label="网址" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">股东及出资信息</span></div>
        <el-table :data="developInfo.enterpriseYearHolderList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="shareholderName" label="股东" ></el-table-column>
          <el-table-column prop="investAmount" label="认缴出资额（万元）" ></el-table-column>
          <el-table-column prop="investDate" label="认缴出资时间" ></el-table-column>
          <el-table-column prop="investType" label="认缴出资方式" ></el-table-column>
          <el-table-column prop="actualInvestAmount" label="实缴出资额（万元）" ></el-table-column>
          <el-table-column prop="actualInvestDate" label="实缴出资时间" ></el-table-column>
          <el-table-column prop="actualInvestType" label="实缴出资方式" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">对外投资信息</span></div>
        <el-table :data="developInfo.enterpriseYearInvestList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="shareholderName" label="股东" ></el-table-column>
          <el-table-column prop="investAmount" label="认缴出资额（万元）" ></el-table-column>
          <el-table-column prop="investDate" label="认缴出资时间" ></el-table-column>
          <el-table-column prop="investType" label="认缴出资方式" ></el-table-column>
          <el-table-column prop="actualInvestAmount" label="实缴出资额（万元）" ></el-table-column>
          <el-table-column prop="actualInvestDate" label="实缴出资时间" ></el-table-column>
          <el-table-column prop="actualInvestType" label="实缴出资方式" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业财务状况</span></div>
        <el-descriptions  :column="2" border style="margin: 15px 0; font-size:12px;">
          <el-descriptions-item label="资产总额" label-class-name="my-label width146" content-class-name="my-content">{{developInfo.enterpriseYearFinance.assetAmount}}</el-descriptions-item>
          <el-descriptions-item label="所有者权益合计"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.equityTotal}}</el-descriptions-item>
          <el-descriptions-item label="销售总额"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.saleAmount}}</el-descriptions-item>
          <el-descriptions-item label="利益总额"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.interestAmount}}</el-descriptions-item>
          <el-descriptions-item label="营业额收入中主营业务收入"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.mainAmount}}</el-descriptions-item>
          <el-descriptions-item label="净利润"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.profitAmount}}</el-descriptions-item>
          <el-descriptions-item label="纳税总额"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.taxAmount}}</el-descriptions-item>
          <el-descriptions-item label="负债总额"  label-class-name="my-label width146">{{developInfo.enterpriseYearFinance.debtAmount}}</el-descriptions-item>
        </el-descriptions>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">对外担保信息</span></div>
        <el-table :data="developInfo.enterpriseYearGuarantList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <!-- <el-table-column prop="date" label="序号" ></el-table-column> -->
          <el-table-column prop="guarantor" label="担保方" ></el-table-column>
          <el-table-column prop="securedParty" label="被担保方" ></el-table-column>
          <el-table-column prop="guarantType" label="担保方式" ></el-table-column>
          <el-table-column prop="guarantAmount" label="担保金额（万元）" ></el-table-column>
          <el-table-column prop="execStatus" label="履行状态" ></el-table-column>
          <el-table-column prop="announceDate" label="公告日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">年报修改记录</span></div>
        <el-table :data="developInfo.enterpriseYearModifyList" border  style="margin: 15px 0; font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="modifyItem" label="修改事项" ></el-table-column>
          <el-table-column prop="before" label="修改前" ></el-table-column>
          <el-table-column prop="after" label="修改后" ></el-table-column>
          <el-table-column prop="modifyDate" label="修改日期" ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="关系信息" name="six"   style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业对外投资</span></div>
        <el-table :data="relationInfo.enterpriseInvestList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="enterprise" label="被投企业名称" ></el-table-column>
          <el-table-column prop="regAmount" label="注册资本（万元）" ></el-table-column>
          <el-table-column prop="provideAmount" label="出资金额（万元）" ></el-table-column>
          <el-table-column prop="provideRate" label="出资比例" ></el-table-column>
          <el-table-column prop="regStatus" label="登记状态" ></el-table-column>
          <el-table-column prop="setupDate" label="成立日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">历史退出对外投资</span></div>
        <el-table :data="relationInfo.enterpriseInvestHistoryList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="enterprise" label="企业名称" ></el-table-column>
          <el-table-column prop="entryDate" label="进入时间" ></el-table-column>
          <el-table-column prop="exitDate" label="退出时间" ></el-table-column>
          <el-table-column prop="holdDuration" label="持股时长" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">分支机构</span></div>
        <el-table :data="relationInfo.enterpriseBranchList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="branchName" label="分支机构名称" ></el-table-column>
          <el-table-column prop="province" label="所属省份" ></el-table-column>
          <el-table-column prop="regStatus" label="登记状态" ></el-table-column>
          <el-table-column prop="setupDate" label="成立日期" ></el-table-column>
        </el-table>
        <!-- <div class="title">       <span class="nodeblue"></span>  <span class="title3">法人代表对外投资</span></div>
        <el-table :data="relationInfo.enterpriseLegalInvestList" border  style="margin: 15px 0" header-cell-class-name="my-label1">

          <el-table-column prop="enterprise" label="企业名称" ></el-table-column>
          <el-table-column prop="enterpriseStatus" label="企业状态" ></el-table-column>
          <el-table-column prop="legalPerson" label="法定代表人" ></el-table-column>
          <el-table-column prop="regAmount" label="注册资本" ></el-table-column>
          <el-table-column prop="investAmount" label="认缴出资额" ></el-table-column>
          <el-table-column prop="holdRate" label="持股比例" ></el-table-column>
          <el-table-column prop="setupDate" label="成立日期" ></el-table-column>
        </el-table> -->
        <!-- <div class="title">       <span class="nodeblue"></span>  <span class="title3">法人代表外部任职</span></div>
        <el-table :data="relationInfo.enterpriseLegalJobList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="enterprise" label="任职企业名称" ></el-table-column>
          <el-table-column prop="job" label="职务" ></el-table-column>
          <el-table-column prop="legalPerson" label="法定代表人" ></el-table-column>
          <el-table-column prop="regStatus" label="登记状态" ></el-table-column>
          <el-table-column prop="setupDate" label="成立日期" ></el-table-column>
        </el-table> -->
        <!-- <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业关联人员投资</span></div>
        <el-table :data="relationInfo.enterpriseRelateInvestList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
 
          <el-table-column prop="enterprise" label="企业名称" ></el-table-column>
          <el-table-column prop="enterpriseStatus" label="企业状态" ></el-table-column>
          <el-table-column prop="name" label="姓名" ></el-table-column>
          <el-table-column prop="legalPerson" label="法定代表人" ></el-table-column>
          <el-table-column prop="regAmount" label="注册资本" ></el-table-column>
          <el-table-column prop="investAmount" label="认缴出资额" ></el-table-column>
          <el-table-column prop="holdRate" label="持股比例" ></el-table-column>
          <el-table-column prop="setupDate" label="成立日期" ></el-table-column>
        </el-table> -->
        <!-- <div class="title">       <span class="nodeblue"></span>  <span class="title3">企业关联人员任职</span></div>
        <el-table :data="relationInfo.enterpriseRelateJobList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
   
          <el-table-column prop="name" label="姓名" ></el-table-column>
          <el-table-column prop="job" label="职位" ></el-table-column>
          <el-table-column prop="brief" label="简介" ></el-table-column>
        </el-table> -->
      </el-tab-pane>
      <el-tab-pane label="经营风险" name="seven"   style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">股权出资</span></div>
        <el-table :data="opelawInfo.enterpriseHoldInvestList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="announceDate" label="公示日期" ></el-table-column>
          <el-table-column prop="regNo" label="登记编号" ></el-table-column>
          <el-table-column prop="pledgee" label="出质人" ></el-table-column>
          <el-table-column prop="pledgeeCard" label="出质人证照/证件号码" ></el-table-column>
          <el-table-column prop="pledgeeHoldAmount" label="出质股权数颜" ></el-table-column>
          <el-table-column prop="pledgor" label="质权人" ></el-table-column>
          <el-table-column prop="pledgorCard" label="质权人证照/证件号码" ></el-table-column>
          <el-table-column prop="regDate" label="股权出质设立登记日期" ></el-table-column>
          <el-table-column prop="state" label="状态" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">动产抵押</span></div>
        <el-table :data="opelawInfo.enterpriseMortgageList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="regNo" label="登记编号" ></el-table-column>
          <el-table-column prop="regDate" label="登记日期" ></el-table-column>
          <el-table-column prop="regOrg" label="登记机关" ></el-table-column>
          <el-table-column prop="debtAmount" label="被担保债权数额(万元)" ></el-table-column>
          <el-table-column prop="state" label="状态" ></el-table-column>
          <el-table-column prop="announceDate" label="公示日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">清算信息</span></div>
        <el-table :data="opelawInfo.enterpriseClearList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="head" label="清算组负责人" ></el-table-column>
          <el-table-column prop="member" label="清算组成员" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">抽查检查</span></div>
        <el-table :data="opelawInfo.enterpriseCheckList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="org" label="检查实施机关" ></el-table-column>
          <el-table-column prop="type" label="类型" ></el-table-column>
          <el-table-column prop="checkDate" label="日期" ></el-table-column>
          <el-table-column prop="result" label="结果" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">简易注销</span></div>
        <el-table :data="opelawInfo.enterpriseLogoffList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="announce" label="公告名称" ></el-table-column>
          <el-table-column prop="announceDate" label="公告期" ></el-table-column>
          <el-table-column prop="result" label="简易注销结果" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">经营异常</span></div>
        <el-table :data="opelawInfo.enterpriseExceptionList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="includeDate" label="列入日期" ></el-table-column>
          <el-table-column prop="includeReason" label="列入经营异常名录原因" ></el-table-column>
          <el-table-column prop="includeOrg" label="列入做出决定机关" ></el-table-column>
          <el-table-column prop="excludeDate" label="移出日期" ></el-table-column>
          <el-table-column prop="excludeReason" label="移出经营异常名录原因" ></el-table-column>
          <el-table-column prop="excludeOrg" label="移出做出决定机关" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">信用中国</span></div>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">行政许可</span></div>
        <el-table :data="opelawInfo.enterpriseLicenseList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="licenseNo" label="行政许可决定文书号" ></el-table-column>
          <el-table-column prop="approveType" label="审核类型" ></el-table-column>
          <el-table-column prop="content" label="内容许可" ></el-table-column>
          <el-table-column prop="validDate" label="许可有效期" ></el-table-column>
          <el-table-column prop="endDate" label="许可截止日期" ></el-table-column>
          <el-table-column prop="org" label="许可机关" ></el-table-column>
          <el-table-column prop="modifyDate" label="更新时间" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">行政处罚</span></div>
        <el-table :data="tableData" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="date" label="行政许可决定文书号" ></el-table-column>
          <el-table-column prop="date" label="违法行为类型" ></el-table-column>
          <el-table-column prop="date" label="行政处罚内容" ></el-table-column>
          <el-table-column prop="date" label="决定书生效日期" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">守信红名单</span></div>
        <el-table :data="opelawInfo.enterpriseRedListList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <!-- <el-table-column prop="date" label="序号" ></el-table-column> -->
          <el-table-column prop="type" label="红名单类别" ></el-table-column>
          <el-table-column prop="qty" label="数量" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">失信黑名单</span></div>
        <el-table :data="opelawInfo.enterpriseBlackListList" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <!-- <el-table-column prop="date" label="编号" ></el-table-column> -->
          <el-table-column prop="type" label="黑名单类别" ></el-table-column>
          <el-table-column prop="qty" label="数量" ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="上市信息" name="eight"   style="padding: 12px 36px">
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">基本信息</span></div>
        <el-descriptions  :column="2" border style="margin: 15px 0; font-size:12px;">
          <el-descriptions-item label="公司名称" label-class-name="my-label width146" content-class-name="my-content">{{listInfo.enterpriseInfo.name}}</el-descriptions-item>
          <el-descriptions-item label="英文名称"  label-class-name="my-label width146">{{listInfo.enterpriseInfo.engName}}</el-descriptions-item>
          <el-descriptions-item label="股票代码"  label-class-name="my-label width146">{{listInfo.enterpriseInfo.stockCode}}</el-descriptions-item>
          <el-descriptions-item label="股票名称"  label-class-name="my-label width146">{{listInfo.enterpriseInfo.stockName}}</el-descriptions-item>
          <el-descriptions-item label="上市时间"  label-class-name="my-label width146">{{listInfo.enterpriseInfo.listDate}}</el-descriptions-item>
        </el-descriptions>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">十大股东</span></div>
        <el-table :data="listInfo.enterpriseHolderList" border  style="margin: 15px 0;font-size:12px;" header-cell-class-name="my-label1">
          <el-table-column prop="shareholderName" label="股东名称" ></el-table-column>
          <el-table-column prop="investQty" label="持股数量(股)" ></el-table-column>
          <el-table-column prop="investRate" label="持股比例" ></el-table-column>
          <el-table-column prop="shareholderType" label="股东性质" ></el-table-column>
        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">资产负债表</span>
 
          <el-select v-model="valueYearOne" placeholder="请选择" size="mini" style="float:right;">
    <el-option
      v-for="(item,index) in listInfo.enterpriseLiabilitiesList"
      :key="index"
      :label="item.year"
      :value="index">
    </el-option>
  </el-select>
        </div>
        <el-table :data="listInfo.enterpriseLiabilitiesList.length>0? listInfo.enterpriseLiabilitiesList[valueYearOne].data:[]" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="target" label="名称" ></el-table-column>
          <el-table-column prop="03-31" :label="this.valueYearDev+'-03-31'" ></el-table-column>
          <el-table-column prop="06-30" :label="this.valueYearDev+'-06-30'" ></el-table-column>
          <el-table-column prop="09-30" :label="this.valueYearDev+'-00-30'" ></el-table-column>
          <el-table-column prop="12-31" :label="this.valueYearDev+'-12-31'" ></el-table-column>
      

        </el-table>
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">利润表</span>
          <el-select v-model="valueYearTwo" placeholder="请选择" size="mini" style="float:right;">
    <el-option
      v-for="(item,index) in listInfo.enterpriseProfitList"
      :key="index"
      :label="item.year"
      :value="index">
    </el-option>
  </el-select>
        </div>
        <el-table :data="listInfo.enterpriseProfitList.length>0? listInfo.enterpriseProfitList[valueYearTwo].data:[]" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="target" label="名称" ></el-table-column>
          <el-table-column prop="03-31" :label="this.valueYearDev+'-03-31'" ></el-table-column>
          <el-table-column prop="06-30" :label="this.valueYearDev+'-06-30'" ></el-table-column>
          <el-table-column prop="09-30" :label="this.valueYearDev+'-00-30'" ></el-table-column>
          <el-table-column prop="12-31" :label="this.valueYearDev+'-12-31'" ></el-table-column>
      

        </el-table>
    
        <div class="title">       <span class="nodeblue"></span>  <span class="title3">现金流量表</span>
          <el-select v-model="valueYearThree" placeholder="请选择" size="mini" style="float:right;">
    <el-option
      v-for="(item,index) in listInfo.enterpriseCashFlowList"
      :key="index"
      :label="item.year"
      :value="index">
    </el-option>
  </el-select>
        </div>
        <el-table :data="listInfo.enterpriseCashFlowList.length>0? listInfo.enterpriseCashFlowList[valueYearThree].data:[]" border  style="margin: 15px 0" header-cell-class-name="my-label1">
          <el-table-column prop="target" label="名称" ></el-table-column>
          <el-table-column prop="03-31" :label="this.valueYearDev+'-03-31'" ></el-table-column>
          <el-table-column prop="06-30" :label="this.valueYearDev+'-06-30'" ></el-table-column>
          <el-table-column prop="09-30" :label="this.valueYearDev+'-00-30'" ></el-table-column>
          <el-table-column prop="12-31" :label="this.valueYearDev+'-12-31'" ></el-table-column>
      

        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
  <!--  -->
  <el-dialog
  title="关注"
  :visible.sync="dialogVisibleLingqu"
  width="30%"
  :before-close="handleCloseLingqu" 
  :append-to-body="true">
  <p>该企业关注到：</p>
  <el-button @click="dialogVisibleNewDir=true" type="primary" size="mini" style="margin-bottom:20px;">新建名单</el-button>
  <div style="border:1px solid black;padding:40px;">
    <el-radio-group v-model="radioMingdan">
    <el-radio v-for="item in mingdanList" :key="item" :label="item.id">{{item.name}}</el-radio>
  </el-radio-group>
  </div>
  <span></span>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="btnSureLingqu">确 定</el-button>
  </span>
</el-dialog>
<el-dialog
  title="修改状态"
  :visible.sync="dialogVisibleStatus"
  width="30%"
  :before-close="handleCloseStatus"
  :append-to-body="true">
  <el-input
  type="textarea"
  :rows="2"
  placeholder="请输入内容"
  v-model.trim="textareaStatus">
</el-input>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="btnSureStatus">确 定</el-button>
  </span>
</el-dialog>
<el-dialog
  title="新建名单"
  :visible.sync="dialogVisibleNewDir"
  width="30%"
  :before-close="handleCloseNewDir"
  :append-to-body="true">
  <el-input v-model.trim="inputNewDir" placeholder="请输入名称"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="btnSureNewDir">确 定</el-button>
  </span>
</el-dialog>

    </div>

</div>
</div>
   </div>

 


  </div>
</template>
<script>
import {  detailInfo,basicInfo,legalDanger,knowledgeProperty,performanceInfo,developmentInfo,relationInfo,businessDanger,listingInformation} from "../../request/moudle/map/enterprise"
import { createCollectName,myCollectNameList,batchReceiveEnterprise,updateEnterpriseStatus} from "../../request/moudle/map/huaxiang"
import {downloadReport} from '../../request/moudle/map/common'
import ProgressOne  from "../../components/zkwl/MyProgress/ProgressOne.vue";
import ProgressTwo  from "../../components/zkwl/MyProgress/ProgressTwo.vue";
import ProgressThree   from "../../components/zkwl/MyProgress/ProgressThree.vue";
import FangziHead from "../../components/zkwl/FangziHead.vue";
export default {
  name: "companyxq",
  data(){
    return {
      tableData:[],
      nowId:0,
      ss_companyxq:null,
      activeName:'first',//当前选中项
      allInfo:{
     
      },//头部信息
      baseInfo:{
        enterpriseManager:{}
      },//基本信息
      lawInfo:{},//法律风险
      knowledgeInfo:{},//知识产权
      opesituationInfo:{
        enterpriseTradeReg:{}
      },//经营状况
      developInfo:{
        enterpriseYearInfo:{},
        enterpriseYearFinance:{}
      },//企业发展
      relationInfo:{},//关系信息
      opelawInfo:{},//经营风险
      listInfo:{
        enterpriseInfo:{},
        enterpriseLiabilitiesList:[],
        enterpriseProfitList:[],
        enterpriseCashFlowList:[],
      },//上市信息
      dialogVisibleLingqu:false,//关注对话框
      dialogVisibleStatus:false,//更改状态
      radioMingdan:null,//
      mingdanList:[],//名单列表
      dialogVisibleNewDir:false,//新建名单
      inputNewDir:null,//新建名单名字
      textareaStatus:null,//修改状态名字
      visibleDownload:false,//报告类型
      valueYearOne:0,
      valueYearTwo:0,
      valueYearThree:0,
      yearList:[],
      valueYearDev:null,
      tabPosition: 'left',
      activeTab:null,
    }
  },
  watch: {
    activeName: {
        handler(newVal, oldVal) {
          this.getParInfo();

        },
        // deep: true,
        // immediate: true
    },

},

  created(){
    this.getYearList();
    this.valueYearDev=this.yearList[0];
    // const obj= sessionStorage.getItem('parampass_companyxq')
  //  if(obj==undefined){

  //  }else{
  //   this.ss_companyxq=JSON.parse(obj);
  //  }
  //  console.log('参数',this.ss_companyxq);
  //  if(this.ss_companyxq!=null){
  //   this.nowType=this.ss_companyxq.local==1?'本地企业':'优企列表';
  //  }
  },
  mounted(){
    const ps= this.$route.query;
    this.nowId=ps.id;
    // alert(JSON.stringify(ps));
    this.getEntDetail();
    this.getEntDetailJibenxinxi();
  },
  methods:{
    tabChange(val){
     
      const that=this;
      // sessionStorage.removeItem("parampass_huaxiang");
      //   sessionStorage.setItem(
      //     "parampass_huaxiang",
      //     JSON.stringify({ type: val,name: ''})
      //   );
      setTimeout(function(){

        that.$router.push({
        path: '/hhzt',query:{
          type:that.activeTab
        }
      });
      },0)
      
      // this.$bus.$emit("refresh_Huaxiang");
    },
    btnYearDev(){
      this.getEntDetailQiyefazhan();
    },
    //获取最近的十年
    getYearList(){
      let yearArr = []

for (let i = new Date().getFullYear()-1; i > new Date().getFullYear() - 11; i--) {

    yearArr.push(
i.toString()
        // {

        //     label: i.toString(),

        //     value: i.toString()

        // }
        )

}
this.yearList=yearArr;

    },
    //跳转画像
    gotoHuaxiang(valP,valP2){
    
        // sessionStorage.removeItem('parampass_huaxiang');
        // sessionStorage.setItem('parampass_huaxiang',JSON.stringify({type:valP,sontype:valP2,id:this.nowId,name:this.allInfo.name}));
      
      //缓存参数
      this.$router.push({
            path:'/hhzt',query:{
              id:this.nowId,name:this.allInfo.name,type:valP,typeson:valP2
            }
          })
          // this.$bus.$emit('refresh_Huaxiang')  
    },
    //下载报告
    btnDownReport(valP){
      this.visibleDownload=false;
      downloadReport({id:this.nowId,reportType:valP}).then((res) => {
// 创建一个 blob 对象
const blob = new Blob([res.data]);
    // 创建一个指向 blob 的 URL
    const url = window.URL.createObjectURL(blob);
    // 创建一个 <a> 元素
    const link = document.createElement('a');
    // 设置 <a> 元素的属性
    link.href = url;
    link.download = Math.random()*100000000+'.docx'; // 设置下载文件的名称
    // 添加到 DOM 中
    document.body.appendChild(link);
    // 触发点击事件
    link.click();
    // 释放 URL 对象
    window.URL.revokeObjectURL(url);
    // 移除 <a> 元素
    document.body.removeChild(link);
      }).catch(err=>{

      })
    },
    //确认关注
    btnSureLingqu(){
      if(this.radioMingdan==null){
        this.$message.error('请选择名单');
      }else{
        batchReceiveEnterprise({
  "ids": [this.nowId],
  "nameId": this.radioMingdan
}).then(res=>{
  if(res.code==200){
    this.$message.success('关注成功');
    this.radioMingdan=null;
    this.mingdanList=[];
    this.dialogVisibleLingqu=false;
  }
}).catch()
      }
    },
    //关闭关注
    handleCloseLingqu(){
      this.radioMingdan=null;
      this.mingdanList=[];
      this.dialogVisibleLingqu=false;
    },
       //查询名单列表
       getCollectName(){
        let query={...{size:1000,current:1}}
      myCollectNameList(query).then(res=>{
      console.log('名单',res);
      this.mingdanList=res.data.records;
    }).catch();
    },
    //取消修改状态
    handleCloseStatus(){
      this.textareaStatus=null;
      this.dialogVisibleStatus=false;
    },  
    //确定修改状态
    btnSureStatus(){
      if(this.textareaStatus==''||this.textareaStatus==null){
        this.$message.error('内容不能为空');
      }else{
        updateEnterpriseStatus({
  "enterpriseId": this.nowId,
  "receiveStatus": this.textareaStatus
}).then(res=>{
          if(res.code==200){
            this.$message.success('修改状态成功');
            this.dialogVisibleStatus=false;
            this.getEntDetail();
          }
        }).catch()
      }
    },
    //取消新建名单
    handleCloseNewDir(){
      this.inputNewDir=null;
      this.dialogVisibleNewDir=false;
    }, 
    //确定添加名单
    btnSureNewDir(){
      if(this.inputNewDir!=null &&this.inputNewDir!=''){
        createCollectName({name:this.inputNewDir}).then(res=>{
          if(res.code==200){
            this.$message.success('添加成功')
            this.inputNewDir=null;
            this.dialogVisibleNewDir=false;
            this.getCollectName();
          }
        }).catch();
      }else{
        this.$message.error('名称不能为空');
      }
    },
    //关注按钮
    btnLingqu(){
      this.dialogVisibleLingqu=true;
      this.getCollectName();
    },
    //修改状态按钮
    btnEditStatus(){
        this.dialogVisibleStatus=true;
        this.textareaStatus=this.allInfo.collectRemark;
    },
    //头部信息
    getEntDetail(){
      this.allInfo={};
      // const id=this.nowId;
      detailInfo({id:this.nowId}).then(res=>{
        console.log('详情',res);
        this.allInfo=res.data;
      }).catch(err=>{
    });
    },
    //基本信息
    getEntDetailJibenxinxi(){
      this.baseInfo={
        enterpriseManager:{}
      };
      // const id=this.nowId;
      basicInfo({id:this.nowId}).then(res=>{
        this.baseInfo=res.data;
      }).catch(err=>{
    });
},
//司法风险
getEntDetailSifafengxian(){
  this.lawInfo={};
  const id=this.nowId;
  legalDanger({id}).then(res=>{
    this.lawInfo=res.data;
  }).catch(err=>{
    });
},
//知识产权
getEntDetailZhishichanquan(){
  this.knowledgeInfo={};
  const id=this.nowId;
  knowledgeProperty({id}).then(res=>{
    this.knowledgeInfo=res.data;
  }).catch(err=>{
    });
},
//经营状况
getEntDetailJingyingzhuangkuang(){
  this.opesituationInfo={
    enterpriseTradeReg:{}
  };
  const id=this.nowId;
  performanceInfo({id}).then(res=>{
    this.opesituationInfo=res.data;
  }).catch(err=>{
    });
},
//企业发展
getEntDetailQiyefazhan(){
  this.developInfo={
    enterpriseYearInfo:{},
    enterpriseYearFinance:{}
  };
  const id=this.nowId;
  developmentInfo({id,year:this.valueYearDev}).then(res=>{
    this.developInfo=res.data;
  }).catch(err=>{
    });
},
//关系信息
getEntDetailGuanxixinxi(){
  this.relationInfo={};
  const id=this.nowId;
  relationInfo({id}).then(res=>{
    this.relationInfo=res.data;
  }).catch(err=>{
    });
},
//经营风险
getEntDetailJingyingfengxian(){
  this.opelawInfo={};
  const id=this.nowId;
  businessDanger({id}).then(res=>{
    this.opelawInfo=res.data;
  }).catch(err=>{
    });
},
//上市信息
getListingInfomation(){
  this.listInfo={
    enterpriseInfo:{},
    enterpriseLiabilitiesList:[],
        enterpriseProfitList:[],
        enterpriseCashFlowList:[],
  };
  const id=this.nowId;
  
  listingInformation({id}).then(res=>{
    // res.data.data.enterpriseLiabilitiesList=[
    //   {year:'2021',data:[{id:1,name:2},{id:1,name:2}]},
    //   {year:'2022',data:[{id:1,name:22},{id:1,name:22}]},
    //   {year:'2023',data:[{id:1,name:222},{id:1,name:222}]}
    // ]
    this.listInfo=res.data;

    console.log('数据2',this.listInfo);
    console.log('数据',this.listInfo.enterpriseLiabilitiesMap);
  }).catch(err=>{
    });
},



    //格式化进度
    format(val){
      return val
    },
    //切换信息
    getParInfo(){
      const param=this.activeName;
      // this.$message.error(this.activeName)
      //
      this.valueYearOne=0;
      this.valueYearTwo=0;
      this.valueYearThree=0;
      this.valueYearDev=this.yearList[0];
      switch (param) {
  case "first":
   this.getEntDetailJibenxinxi();
    break;
  case "second":
   this.getEntDetailSifafengxian();
    break;
  case "third":
    this.getEntDetailZhishichanquan();
    break;
  case "fourth":
  this.getEntDetailJingyingzhuangkuang();
    break;
  case "five":
   this.getEntDetailQiyefazhan();
    break;
    case "six":
this.getEntDetailGuanxixinxi();
    break;
    case "seven":
this.getEntDetailJingyingfengxian();
    break;
    case "eight":
    this.getListingInfomation();
    break;
  default:
    console.log("I don't own a pet");
    break;
}
    },
    //切换信息栏
    handleClick(){
      return;
  
    }
  },
  components: {
    ProgressOne,
    ProgressTwo,
    ProgressThree,
    FangziHead,
  },
}
</script>
<style lang="scss" scoped>

.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.searchBar {
  display: flex;
  .searchInput {
     width: 30%;
  }
  .tabChange {
    text-align: right;
  }
}
/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  border-bottom: 1px solid #20a0ff;
  font-weight: bold;
  //background: url("../../assets/img/map/border.png") 0px 28px no-repeat;
  background-size: contain;
}
/deep/ .my-label{
  background: #F1F6FB;
}
/deep/ .my-label1{
  background-color: #F1F6FB!important;
}
/deep/ .width146{
  width: 146px;
}
.title{
  font-weight: 550;
}
  .company{
    // padding: 0 50px!important;
    padding-bottom: 50px !important;
    font-size: 14px;
    position:relative;
    padding:20px 105px 50px 105px;
    .name{
      font-size: 18px;
      font-weight: 550;
      margin: 30px 0 24px 0;
    }
    .h1{
      padding: 16px;
      background: #F9F9F9;
      border: 1px dashed #ccc;
    }
    .h2{
      background: #fff;
      text-align: center;
      padding: 30px 0;
      border: 1px dashed #ccc;
      border-top: none;
    }
    .right{
      margin-left: 33px;
      width: 380px;
      .circle{
        width: 50px;
        height: 50px;
        // background: #0c8aff;
        border-radius: 50%;
        margin-right: 15px;
      }
      .bottoms{
        background: #fff;
        padding: 15px 75px;
        margin-bottom: 15px;
        border: 1px dashed #ccc;
        cursor: pointer;
      }
      .bottom1{
        color: #797979;
        font-size: 12px;
        line-height: 25px;
      }
    }
    .line{
      background: #fff;
      margin: 0 0 16px 0;
      padding: 18px 80px;
      color:#2E2E2E;
      font-size:15px;
      font-weight:bold;
      border: 1px dashed #ccc;
    }
  }
  .btn{
    margin: 0 10px;
    width: 180px;
    background: linear-gradient(#0078F2, #00BAFF); 
  }
  .tuputext{
    margin-top:6px;
    color:#2E2E2E;
    font-size:15px;
    font-weight: bold;
  }
  /deep/.el-descriptions-item__content{
    width:40%;
  }

  .nodeblue{
      display: inline-block;
      width:11px;
      height: 11px;
      border-radius: 11px;
      background: linear-gradient(to top, #0078F2, #00BAFF);//从下到上
      margin-right: 4px;
    }
    .title3{
      color:#4D4D4D;
      font-size:14px;
      font-weight:bold;
    }
    .genjinxinxi{
      color:#4D4D4D ;
      font-size:14px;
      margin:36px 0 36px 0;
    }
    .cctitle{
      color:#2E2E2E;
      font-size:15px;
      font-weight:bold;
      margin-top:16px;
    }
    /deep/ .el-progress--circle .el-progress__text {
      font-size: 24px !important;
    }
    /deep/ .el-table .cell {
      line-height: 20px;
    }
     
    //调节表格行间距
    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
       padding: 8px 10px;
    }

    /deep/ .el-table .el-table__cell { 
      padding: 8px 0 !important;
    }

  /deep/.manage .el-descriptions-item__content{
    width:80%;
  }
  /deep/ .el-table { 
    font-size: 12px;
  }
    // /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active { 
    //    background: url("../../assets/img/map/border.png") 0px 37px no-repeat;
    //    border: none;

    // }
  .firstCircle {
    /deep/ {
	  .el-progress {
	    margin: 0 6px;
	    position: relative;
	  }
	  // 设置渐变进度条，通过重新定义svg标签的url
	  svg>path:nth-child(2) {
	  	// #write 此处的id就是定义的svg标签id 做替换即可 
	    stroke: url(#write); 
	  }
	  // 进度条的图形样式，控制翻转
	  .el-progress-circle {
	    transform: rotateY(180deg);
	  }
	}
}
.fs_head{
      /deep/ .el-progress--circle .el-progress__text {
      // font-size: 30px !important;
      font-size:16px;
    }
}
// .huaxiangzones{
//   /deep/.el-radio-button__inner{
//     width:160px;
//   }
// }
.huaxiangzones{
  /deep/.el-radio-button__inner{
    width:100px;
  }

  margin-top: -20px;
    margin-right: 50px;
}
</style>
